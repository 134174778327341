import { render, staticRenderFns } from "./LayoutMain.vue?vue&type=template&id=edacaeb8&"
import script from "./LayoutMain.vue?vue&type=script&lang=js&"
export * from "./LayoutMain.vue?vue&type=script&lang=js&"
import style0 from "./LayoutMain.vue?vue&type=style&index=0&id=edacaeb8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports