<template>
  <aside class="main-sidebar vh-100 d-flex flex-column overflow-auto">
    <div class="main-sidebar__header">
      <div class="logo">
        <!-- This srcset most likely needs matching with sizes -->
        <img
          srcset="
            ../assets/img/logo-icon.png  767w,
            ../assets/img/logo-white.png 768w
          "
          src="../assets/img/logo-icon.png"
          alt="Menaget"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="main-sidebar__body">
      <ul class="main-sidebar__nav">
        <li class="main-sidebar__item mb-3">
          <b-button
            block
            variant="link"
            class="text-decoration-none"
            active-class="btn-secondary"
            to="/dashboard"
          >
            <CategoryIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Dashboard</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-4">
          <b-button
            block
            active-class="btn-secondary"
            class="text-decoration-none"
            variant="link"
            to="/skills"
          >
            <SkillsIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Skills</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-3">
          <b-button
            block
            variant="link"
            class="text-decoration-none"
            active-class="btn-secondary"
            to="/stand-up"
          >
            <ArrowsIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Stand Up</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-4">
          <b-button
            block
            active-class="btn-secondary"
            class="text-decoration-none"
            variant="link"
            to="/timesheet"
          >
            <TimesheetIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Timesheet</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-4">
          <b-button
            block
            active-class="btn-secondary"
            class="text-decoration-none"
            variant="link"
            to="/billing"
            disabled
          >
            <BillingIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Billing</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-4">
          <b-button
            block
            active-class="btn-secondary"
            class="text-decoration-none"
            variant="link"
            to="/vacation"
          >
            <VacationIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Vacation</span>
          </b-button>
        </li>
        <li class="main-sidebar__item mb-4">
          <b-button
            block
            active-class="btn-secondary"
            class="text-decoration-none"
            variant="link"
            to="/settings"
          >
            <NotesIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Settings</span>
          </b-button>
        </li>
      </ul>
    </div>
    <div class="main-sidebar__foter mt-auto">
      <ul class="main-sidebar__nav">
        <li class="main-sidebar__item">
          <b-button
            block
            variant="link"
            class="text-decoration-none"
            @click="signOut()"
          >
            <LogoutIcon class="mr-0 mr-sm-2" />
            <span class="d-none d-md-inline">Log Out</span>
          </b-button>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
import CategoryIcon from "../components/svg/CategoryIcon.vue";
import SkillsIcon from "../components/svg/SkillsIcon.vue";
import TimesheetIcon from "../components/svg/TimesheetIcon.vue";
import LogoutIcon from "../components/svg/LogoutIcon";
import BillingIcon from "../components/svg/BillingIcon";
import NotesIcon from "../components/svg/NotesIcon";
import ArrowsIcon from "../components/svg/ArrowsIcon";
import VacationIcon from "../components/svg/VacationIcon.vue"

export default {
  components: {
    CategoryIcon,
    SkillsIcon,
    TimesheetIcon,
    LogoutIcon,
    BillingIcon,
    NotesIcon,
    ArrowsIcon,
    VacationIcon,
  },
  data() {
    return {
      activeClass: true,
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.main-sidebar {
  background-color: $primary-color;
  width: 20%;
  min-width: 288px;
  max-width: 288px;
  padding: 2.55% 2.55% 1.525% 2.55%;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    min-width: 80px;
    max-width: 80px;
    padding: 10px 10px;
  }
  &__header {
    margin-bottom: 100px;
  }
  &__nav {
    list-style: none;
    padding: 0;
  }
  &__item {
    .btn {
      color: $white;
      height: $sidebar-nav-item-height;
      font-size: 14px;
      font-weight: 600;
      padding: 1rem 2.375rem;
      text-align: left;
      &.btn-secondary:not(:disabled):not(.disabled).active {
        background-color: $secondary-color;
        border-color: $secondary-color;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding: 1rem 1rem;
      }
    }
    .btn-link {
      &:hover {
        background-color: $secondary-color;
      }
    }
  }
}
.logo {
  width: 100%;
  max-width: 203px;
  @include media-breakpoint-down(sm) {
    max-width: 80px;
  }
}
</style>
